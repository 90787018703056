<template>
  <div>
    <v-dialog v-model="dialogCreate" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small color="success" dark v-bind="attrs" v-on="on">
          {{ $t('MENU.ENTERPRICE.INCOMES.SUBCATEGORY') }}+
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Kategoriya SubKategoriya qo'shish </span>
        </v-card-title>
        <v-card-text>
          <div style="display: flex; flex-wrap: wrap">
            <div class="col-6 pl-0">
              <v-text-field
                label="SubKategoriya daromad nomi"
                v-model="nameInput"
                outlined
                dense
              ></v-text-field>
            </div>

            <div class="col-6 pr-0">
              <v-text-field
                label="Kod"
                v-model="codeInput"
                maxLength="4"
                outlined
                dense
                minLength="4"
              ></v-text-field>
            </div>
            <div class="col-12 px-0">
              <v-autocomplete
                :items="getMainIncomeCategories"
                v-model="selectCategoriesProfit"
                dense
                outlined
                item-text="name"
                item-value="id"
                label="Asosiy daromadlar kategoriya"
              ></v-autocomplete>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            :disabled="newsubcategoryLoading"
            @click="dialogCreate = false"
            >Bekor qilish</v-btn
          >
          <v-btn
            color="success"
            :disabled="newsubcategoryLoading"
            @click="submit"
          >
            <i v-if="newsubcategoryLoading" class="el-icon-loading"></i>
            Yuborish</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsubcategoryLoading: false,
      dialogCreate: false,
      selectCategoriesProfit: '',
      nameInput: '',
      codeInput: '',
      ss: ''
    }
  },
  created() {
    this.$store.dispatch('getMainProfitCategory')
  },

  computed: {
    getMainIncomeCategories() {
      return this.$store.state.requests.mainProfitCategory
    },
    getMainIncome() {
      return this.$store.state.requests.mainProfit
    }
  },
  methods: {
    submit() {
      const data = {
        name: this.nameInput,
        code: this.codeInput,
        category: this.selectCategoriesProfit
      }
      console.log(data)
      this.newsubcategoryLoading = true
      this.$store
        .dispatch('createSubCategoryProfit', data)
        .then(() => {
          this.newsubcategoryLoading = false
          this.nameInput = ''
          this.codeInput = ''
          this.selectCategoriesProfit = ''
          this.dialogCreate = false
        })
        .catch((err) => {
          this.newsubcategoryLoading = false
          console.error(err)
        })
    }
  }
}
</script>

<style></style>
