<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">
                  {{ $t('MENU.ENTERPRICE.INCOMES.SUBCATEGORY') }}
                </h3>
              </div>
              <div class="card-button">
                <create />
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                v-if="!loading"
                :headers="headers"
                :loading="isLoading"
                loading-text="...."
                :items="getData"
                :page.sync="page"
                :items-per-page="10"
                class="row-pointer"
                single-select
                @click:row="rowClick"
                item-key="id"
                hide-default-footer
              >
                <template v-slot:[`item.index`]="{ item }">
                  {{
                    getData
                      .map(function (x) {
                        return x.id
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div style="text-align: right !important">
                    <action :index="item"></action>
                  </div> </template
              ></v-data-table>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="Math.ceil(getData.length / 10)"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import action from './action'
import create from './create'

export default {
  data() {
    return {
      page: 1,
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1
    }
  },
  beforeCreate() {
    this.$store.dispatch('getMainProfitSubCategory')
    this.$store.dispatch('getMainProfitCategory')
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.ENTERPRICE.TITLE') },
      { title: this.$t('MENU.ENTERPRICE.INCOMES.TITLE') },

      { title: this.$t('MENU.ENTERPRICE.INCOMES.SUBCATEGORY') }
    ])
  },
  computed: {
    headers() {
      return [
        { text: '#', value: 'index' },
        {
          text: this.$t('TABLE_HEADER.MAIN_INCOME'),
          value: 'main_income_info.name'
        },
        {
          text: this.$t('TABLE_HEADER.MAIN_INCOME_CAT'),
          value: 'category_info.name'
        },
        { text: this.$t('TABLE_HEADER.MAIN_INCOME_SUBCAT'), value: 'name' },
        { text: this.$t('TABLE_HEADER.CODE'), value: 'code' },
        { text: '', value: 'action', sortable: false }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getData() {
      return this.$store.state.requests.mainProfitSubCategory
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    }
  },
  components: {
    create,
    action
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
